import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import Title from "react-titles/Title10";
import './styles.css'
import App from './App'

function Overlay() {
  return (
    <div style={{width: '100%'}}>
      <Title size="400" text1="PRYCE" text2="NEVERLAND" open={true} style={{ color: "black", position: 'absolute', top: 40, left: 40, pointerEvents: 'none',  }}/>
    </div>
  )
}

ReactDOM.render(
  <>
    <App />
    <Overlay />
    <a href='https://www.instagram.com/pryceduh/?hl=en' target="_blank">
      <FontAwesomeIcon icon={faInstagram} size="4x" style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} />
    </a>
    <a href='https://open.spotify.com/artist/6H3O1u6iVONELLtNldM5i5' target="_blank">
      <FontAwesomeIcon icon={faSpotify} size="4x" style={{ position: 'absolute', bottom: 40, right: 40, width: 30 }} />
    </a>
    <a href='https://music.apple.com/us/artist/pryce/475634399' target="_blank">
      <FontAwesomeIcon icon={faMusic} size="4x" style={{ position: 'absolute', bottom: 40, right: 100, width: 30 }} />
    </a>
    <a href='https://www.youtube.com/user/PryceMusicATL' target="_blank">
      <FontAwesomeIcon icon={faYoutube} size="8x" style={{ position: 'absolute', top: 40, right: 40, width: 30 }} />
    </a>

  </>,
  document.getElementById('root')
)
